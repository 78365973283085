#navHeaderContainer {
  z-index: 1;
  right: 0;
  left: 0;
}

.navItemsContainer {
  display: flex;
  align-items: center;
}

.navBtn {
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1em;
  cursor: pointer;
  font-weight: 500;
  color: white;
  text-decoration: none;
  user-select: none;
  text-transform: uppercase;
}
.navBtn:hover {
  background-color: #fb8501;
  border-radius: 5px;
  color: white;
}

.navBtn:hover > i {
  fill: #fb8501;
}

#menuIcon {
  display: none;
}
#logoHead > img {
  width: 350px;
  transition: all 2s;
}
#loginBtn {
  box-shadow: 5px 5px 5px rgb(67, 67, 67);
}
#loginBtn:hover {
  box-shadow: none;
}

@media screen and (max-width: 1130px) {
  .navBtn {
    font-size: 0.9em;
    padding: 5px 8px;
  }
  #loginBtn {
    font-size: 0.9 !important;
  }
  #logoHead > img {
    width: 300px !important;
  }
}

@media screen and (max-width: 960px) {
  .navBtn {
    font-size: 0.8em;
    padding: 5px 6px;
  }
  #loginBtn {
    font-size: 0.8em !important;
  }
  #logoHead > img {
    width: 280px !important;
  }
}

@media screen and (max-width: 840px) {
  .navBtn {
    font-size: 0.7em;
    padding: 5px 3px;
  }
  #loginBtn {
    font-size: 0.7em !important;
    padding: 5px 10px;
  }
  #logoHead > img {
    width: 260px !important;
  }
}
@media screen and (max-width: 670px) {
  #logoHead > img {
    width: 200px !important;
  }
}

@media screen and (max-width: 620px) {
  .navItemsContainer {
    flex-direction: column;
    position: absolute;
    background-color: #373f68;
    width: 100%;
    padding: 15px;
    z-index: 10;
    top: 80px;
  }
  .navItemsContainer > div {
    width: 100%;
  }
  .navBtn {
    font-size: 10px;
    width: 100%;
    margin-left: 0px;
  }
  #logoHead > img {
    width: 150px;
  }
  #logoHead {
    align-self: center;
  }
  #navHeaderContainer {
    justify-content: center !important;
  }
  #menuIcon {
    display: block;
    position: absolute;
    left: 30px;
    font-size: 1.5em;
  }
}
