.sliderBody {
  display: flex;
  height: 400px;
  overflow: hidden;
  position: relative;
}
.sliderContent {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.sliderBtnLeft,
.sliderBtnRight {
  background-color: #fb8501;
  align-self: center;
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  border-radius: 100%;
  cursor: pointer;
}

.sliderBtnRight {
  right: -25px;
}
.sliderBtnLeft {
  left: -25px;
}

@media screen and(max-width:768px) {
}
