.heroContainer {
  width: 100vw;
  height: 75vh;
  overflow: hidden;
  position: relative;
  display: flex;
}
#myVideo {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.heroContainer > div > h1 {
  width: 40%;
  font-size: 4.5em;
  color: #fff;
  margin: 0.5em 0em;
  text-shadow: 3px 5px rgb(137, 137, 137);
}

@media screen and (max-width: 1250px) {
  .heroContainer > div > h1 {
    width: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .heroContainer > div > h1 {
    font-size: 4em;
  }
}
@media screen and (max-width: 1100px) {
  .heroContainer > div > h1 {
    font-size: 4em;
  }
}
@media screen and (max-width: 840px) {
  .heroContainer > div > h1 {
    font-size: 3.5em;
  }
}
@media screen and (max-width: 720px) {
  .heroContainer > div > h1 {
    font-size: 3em;
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  .heroContainer {
    height: 60vh;
  }
  .heroContainer > div > h1 {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 450px) {
  .heroContainer > div > h1 {
    font-size: 2em;
  }
}
