/* #E67336 */
#whatDoWeDoContainer > h3 {
  color: #e67336;
  font-size: 2em;
  text-align: center;
  width: 70%;
  margin: 1em 1em 2em 1em;
}

#whatDoWeDoContainer > .cardCollection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3em;
  width: 70%;
  justify-content: center;
}
#whatDoWeDoContainer > div > div:first-child {
  grid-column: 1 / span 2;
  margin: 0 3em;
}

#dotted_circle {
  border: '1px dotted black';
  border-radius: '100%';
}

.whatDoWeDoBottomQuotation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1em;
  background-color: #d9d9d93a;
}
.whatDoWeDoBottomQuotation > img {
  width: 120px;
}
.whatDoWeDoBottomQuotation > h3 {
  width: 80%;
  text-align: center;
  font-style: italic;
}
.whatDoWeDoBottomQuotation > h3 > span {
  color: #fb8501;
}

@media screen and (max-width: 1300px) {
  #whatDoWeDoContainer > .cardCollection {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
  #whatDoWeDoContainer > .cardCollection {
    width: 100%;
  }
  #whatDoWeDoContainer > h3 {
    font-size: 2em;
    text-align: center;
    width: 90%;
  }
}
@media screen and (max-width: 786px) {
  #whatDoWeDoContainer > h3 {
    font-size: 1.5em;
    text-align: center;
    width: 100%;
  }

  .whatDoWeDoBottomQuotation > img {
    width: 100px;
  }
  .whatDoWeDoBottomQuotation > h3 {
    width: 90%;
    font-size: 1.4em;
    text-align: center;
    font-style: italic;
  }
}

@media screen and (max-width: 620px) {
  #whatDoWeDoContainer > .cardCollection {
    grid-template-columns: 1fr;
  }
  #whatDoWeDoContainer > div > div:first-child {
    grid-column: 1;
    margin: 0;
  }

  .whatDoWeDoBottomQuotation > img {
    width: 80px;
  }
  .whatDoWeDoBottomQuotation > h3 {
    width: 90%;
    font-size: 1.2em;
    text-align: center;
    font-style: italic;
  }
}

@media screen and (max-width: 425px) {
  #whatDoWeDoContainer > h3 {
    font-size: 1.2em;
    margin: 0.5em 0.5em 1em 0.5em;
  }
  .whatDoWeDoBottomQuotation > img {
    width: 50px;
  }
  .whatDoWeDoBottomQuotation > h3 {
    width: 90%;
    font-size: 1em;
    text-align: center;
    font-style: italic;
  }
}
