.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #8ecae68f;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #023047;
  padding: 2em 0em;
  min-height: 40vh;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  flex: 1;
  height: 65%;
  font-weight: 600;
  color: #023047;
  font-size: 14px;
  margin-right: 3em;
}
.footerHeader {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  height: 30px;
}
.footerLinks > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1em;
  justify-content: space-around;
}
.footerLinks > div > a {
  color: #023047;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}
.footerCopyWrite {
  font-size: 14px;
  font-weight: 200 !important;
  color: #fff;
  padding: 30px 150px;
  background-color: #023047;
}

.footerForm {
  gap: 0 !important;
}
.footerForm > input {
  padding: 5px 10px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 1200px) {
  .footerLinks {
    font-size: 1em;
  }
}
@media screen and (max-width: 900px) {
  .footerLinks {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 768px) {
  .footerLinks {
    font-size: 0.8em;
  }
  .footerCopyWrite {
    padding: 30px;
  }
  .footerHeader {
    font-size: 1.2em;
  }
  .footerCopyWrite {
    font-size: 10px;
    padding: 15px;
  }
  #footerBtn {
    font-size: 0.8em;
    width: fit-content;
  }
}

@media screen and (max-width: 540px) {
  .footerLinks {
    font-size: 0.7em;
  }
  .footerCopyWrite {
    padding: 30px;
  }
  .footerHeader {
    font-size: 1em;
    height: 15px;
  }
  .footerCopyWrite {
    font-size: 10px;
    padding: 15px;
  }
  #footerBtn {
    font-size: 0.8em;
    width: fit-content;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 425px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .footer > div:first-child {
    grid-column: 1 / span 2;
    grid-row: 2;
  }
}
