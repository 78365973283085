#popUpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#popUpContainer > div {
  position: relative;
  z-index: 1;
  background-color: white;
  display: flex;
  height: 80%;
  width: 80%;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 297px 119px rgba(27, 41, 62, 0.01),
    0px 167px 100px rgba(27, 41, 62, 0.05), 0px 74px 74px rgba(27, 41, 62, 0.09),
    0px 19px 41px rgba(27, 41, 62, 0.1), 0px 0px 0px rgba(27, 41, 62, 0.1);
}

#popUpContainer > div > button {
  position: absolute;
  width: fit-content;
  background-color: white;
  right: -15px;
  top: -15px;
  box-shadow: 0px 0px 5px #ccc;
}

.popUpHeader {
  background: linear-gradient(
    135.83deg,
    #191654 -84.22%,
    #219ebc 98.97%
  ) !important;
  flex: 1;
  display: flex;
  padding: 0 2em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.popUpHeader > h4,
.popUpHeader > h2 {
  text-align: center;
}

.popUpContent {
  flex: 1;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  padding: 1em 0;
}
.popUpContent > span {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

@media screen and (max-width: 1000px) {
  #popUpContainer > div {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .popUpHeader > h4 {
    font-size: 1em;
  }
  .popUpHeader > h2 {
    font-size: 1.5em;
  }
  .popUpContent > span {
    text-align: center;
    font-weight: bold;
    font-size: 1em;
  }
}

@media screen and (max-width: 500px) {
  .popUpHeader > h4 {
    font-size: 0.8em;
  }
  .popUpHeader > h2 {
    font-size: 1.2em;
  }
  .popUpContent > span {
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;
  }
}
@media screen and (max-width: 350px) {
  #popUpContainer > div {
    width: 95%;
  }
}
