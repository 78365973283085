.sliderShowCase {
  display: flex;
  gap: 1em;
  height: 50vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  #showCaseContainer {
    padding: 0 2em;
  }
}
