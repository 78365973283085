.nftCampaignContainer {
  display: flex;
  align-items: center;
}
.nftCompaignLeft > div {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
}
.nftCompaignLeft > div > div {
  display: flex;
  align-items: center;
  gap: 1em;
  font-weight: bold;
  font-size: 16px;
}
.nftCompaignLeft > div > div > img {
  width: 70px;
}
.nftCompaignLeft > div > div > span {
  font-size: 1.5em;
  width: 70%;
}
.nftCompaignLeft > div > span {
  font-size: 18px;
  width: 70%;
}

.nftCompaignRight {
  display: flex;
  gap: 1em;
  height: 100%;
}
.nftCompaignRight > img {
  height: 350px;
  object-fit: cover;
}
.nftCompaignRight > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nftCompaignRight > div img {
  width: 120px;
  object-fit: cover;
}
.nftCompaignRight > div > div {
  position: relative;
}
.nftCompaignRight > div > div::after {
  content: ' 3+';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.724);
}

@media screen and (max-width: 1080px) {
  .nftCompaignLeft > h2 {
    font-size: 1.7em;
  }
  .nftCompaignLeft > div > h3 {
    font-size: 1.5em;
  }
  .nftCompaignLeft > div > div > img {
    width: 80px;
  }
  .nftCompaignLeft > div > div > span {
    font-size: 1.1em;
  }
  .nftCompaignLeft > div > span {
    font-size: 1em;
    width: 90%;
  }
  #learnMoreBtn {
    padding: 7px 14px;
    margin: 0.7em 0;
  }
}

@media screen and (max-width: 930px) {
  .nftCompaignRight > img {
    width: 300px;
  }
  .nftCompaignRight > div img {
    width: 100px;
  }
}

@media screen and (max-width: 870px) {
  .nftCompaignRight {
    gap: 0.5em;
  }
  .nftCompaignRight > img {
    width: 270px;
  }
  .nftCompaignRight > div {
    gap: 0.5em;
  }
  .nftCompaignRight > div img {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .nftCompaignLeft > h2 {
    font-size: 1.2em;
  }
  .nftCompaignLeft > div > h3 {
    font-size: 1em;
  }
  .nftCompaignLeft > div > div > img {
    width: 50px;
  }
  .nftCompaignLeft > div > div > span {
    font-size: 0.9em;
  }
  .nftCompaignLeft > div > span {
    font-size: 0.9em;
    width: 90%;
  }
  #learnMoreBtn {
    font-size: 0.9em;
  }
  .nftCompaignRight > img {
    width: 250px;
    height: 270px;
  }
}

@media screen and (max-width: 600px) {
  .nftCampaignContainer {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 425px) {
  .nftCompaignRight {
    display: none;
  }
}
