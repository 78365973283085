.cardContainer {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: #023047;
}
.cardContainer > img {
  width: 100px;
  margin: 2em 0em 1em 0em;
  padding: 0.5em;
  border: 1px dashed #023047;
  border-radius: 100%;
}

@media screen and (max-width: 786px) {
  .cardContainer > img {
    width: 90px;
    margin: 2em 0em 0em 0em;
    padding: 0.5em;
  }
}
