.vettingContainer {
  background-color: #f6f8fb;
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1.5em;
}
.vettingContainer::after {
  content: '';
  background-color: #219ebc;
  position: absolute;
  left: -50%;
  right: -50%;
  top: 50%;
  height: 100%;
  transform: rotate(345deg);
}
.vettingContainer::before {
  content: '';
  position: absolute;
  background-image: url('../../assets/dotpattern.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  right: -2.5%;
  top: 10%;
  width: 150px;
  height: 150px;
  z-index: 10;
}

.vettingContainer > img {
  width: 300px;
}

.vettingContainer > h2 {
}

.vettingContent {
  position: relative;
  z-index: 1;
  background-color: #fff;
  overflow-x: hidden;
  min-height: 80%;
  width: 80%;
  border-radius: 10px;
  align-self: center;
  box-shadow: 0px 297px 119px rgba(27, 41, 62, 0.01),
    0px 167px 100px rgba(27, 41, 62, 0.05), 0px 74px 74px rgba(27, 41, 62, 0.09),
    0px 19px 41px rgba(27, 41, 62, 0.1), 0px 0px 0px rgba(27, 41, 62, 0.1);
}

.vettingContentHeader {
  display: flex;
  background: #f2f2f2;
  padding: 1em 1.5em;
}
.vettingHeaderItem {
  width: 100%;
  display: flex;
}
.vettingHeaderItem:not(:last-child)::after {
  content: '';
  background-image: url('../../assets/selectionVetting.png');
  width: 50px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 40%;
}

@media screen and (max-width: 1000px) {
  .vettingContent {
    width: 90%;
  }

  .vettingContainer > h2 {
    font-size: 2em;
  }
}

@media screen and (max-width: 900px) {
  .vettingHeaderItem:not(:last-child)::after {
    content: '';
    background-image: url('../../assets/selectionVetting.png');
    width: 25px;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 40%;
  }
}

@media screen and (max-width: 670px) {
  .vettingHeaderItem:not(:last-child)::after {
    content: '';
    display: none;
  }
}
@media screen and (max-width: 570px) {
  .vettingContent {
    width: 98%;
  }
  .vettingContainer > h2 {
    font-size: 1.5em;
  }
  .vettingContainer > img {
    width: 250px;
  }
}
