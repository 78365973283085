.companyIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.companyIcon > img {
  width: 7em;
}

@media screen and (max-width: 1024px) {
  .companyIcon > img {
    width: 6em;
  }
}

@media screen and (max-width: 768px) {
  .companyIcon > img {
    width: 5em;
  }
}
