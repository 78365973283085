#marketPlaceTimer > h4 {
  font-size: 1.6em;
  text-align: center;
}
.marketPlaceTimerContainer {
  display: flex;
  gap: 1em;
}
.marketPlaceTimerContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}
.marketPlaceTimerContainer > div > div {
  display: flex;
  align-items: center;
  gap: 1em;
}
.marketPlaceTimerContainer > div > div > .timer {
  background: #ffb703;
  box-shadow: 10px 14px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1em;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5em;
}
.marketPlaceTimerContainer > div > div > .timerDivider {
  font-size: 2em;
}
.marketPlaceTimerContainer > div > p {
  font-weight: 700;
  font-size: 1.2em;
  margin-top: 1em;
}

@media screen and (max-width: 550px) {
  .marketPlaceTimerContainer > div > div > .timer {
    padding: 0.5em;
    width: 50px;
    height: 50px;
    font-size: 1.2em;
  }
  .marketPlaceTimerContainer > div > div > .timerDivider {
    font-size: 2em;
  }
  .marketPlaceTimerContainer > div > p {
    font-weight: 700;
    font-size: 1em;
    margin-top: 0.2em;
  }

  .marketPlaceTimerContainer > div > div {
    gap: 0.5em;
  }
  .marketPlaceTimerContainer {
    gap: 0.5em;
  }
  #marketPlaceTimer > h4 {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 425px) {
  .marketPlaceTimerContainer > div > div > .timer {
    padding: 0.5em;
    width: 40px;
    height: 40px;
    font-size: 1em;
  }
  .marketPlaceTimerContainer > div > div > .timerDivider {
    font-size: 1.5em;
  }
  .marketPlaceTimerContainer > div > p {
    font-weight: 700;
    font-size: 0.8em;
    margin-top: 0.2em;
  }
}
