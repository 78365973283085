.impactOverseCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3em;
}

@media screen and (max-width: 1350px) {
  .impactOverseCardContainer {
    gap: 2.5em;
  }
  .impactOverseCardContainer > div > div > h2 {
    font-size: 1.5em;
  }
  .impactOverseCardContainer > div > div > div {
    padding: 1.5em;
  }

  .impactOverseCardContainer > div > div > div > button {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1200px) {
  .impactOverseCardContainer {
    gap: 2em;
  }
  .impactOverseCardContainer > div > div > img {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1080px) {
  .impactOverseCardContainer {
    grid-template-columns: 1fr 1fr;
    gap: 2em;
  }
  .impactOverseCardContainer > div > div > h2 {
    font-size: 1.2em;
  }
  .impactOverseCardContainer > div > div > div {
    padding: 1.2em;
  }

  .impactOverseCardContainer > div > div > div > button {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1080px) {
  .impactOverseCardContainer {
    grid-template-columns: 1fr;
    gap: 2em;
  }
  .impactOverseCardContainer > div > div > div > button {
    margin-bottom: 1em;
  }

  .impactOverseCardContainer > div > div > h2 {
    font-size: 1.2em;
    height: 60px;
  }
}
